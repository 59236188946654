import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import 'video.js/dist/video.min'
import 'video.js/dist/video-js.min.css'

Vue.config.productionTip = false

import dayjs from 'dayjs'
import 'dayjs/locale/nl'

// internationalitzaion
dayjs.locale('nl') // use loaded locale globally

// add filter
Vue.filter('dayjs', (value: any, format: any) => {
  const d = dayjs(value);

  if (!d.isValid()) {
    console.error('Could not parse into date: ' + value)
    return value
  }

  return d.format(format)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

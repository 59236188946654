import * as firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { SITE } from '@/config'
import { Funeral, Message } from '@/global'

const firebaseApp = firebase.initializeApp({
    "projectId": "liveuitvaard",
    "appId": "1:108980275143:web:1c3070d793648c3173fbe2",
    "databaseURL": "https://liveuitvaard.firebaseio.com",
    "storageBucket": "liveuitvaard.appspot.com",
    "locationId": "europe-west3",
    "apiKey": "AIzaSyDtnpC41gmsGIX7lutoiBZ1cFnKP4MuDy4",
    "authDomain": "liveuitvaard.firebaseapp.com",
    "messagingSenderId": "108980275143"
});

async function getFuneralMessages(funeral: Funeral): Promise<Message[]> {

  const messagesDocs = await firebase.firestore()
    .collection('clients').doc(SITE)
    .collection('funerals').doc(funeral.id as string)
    .collection('messages').get()

  const messages: Array<Message> = []

  messagesDocs.forEach((messageDoc) => {

    const messageData = messageDoc.data()

    const message: Message = {
      name: messageData.name,
      message: messageData.message,
      timestamp: messageData.timestamp.toDate()
    }

    messages.push(message)
  })

  return messages
}


export {
  getFuneralMessages,
  firebase,
  firebaseApp
}

import Vue from 'vue'
import Vuex from 'vuex'

import { firebase } from '@/firebase'

import { Funeral, Message } from '@/global'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        funeral: null,

        funerals: [],

        isAdmin: false, // TODO check on page itself before loading content
        loggedInAs: null,

        site: 'eraly' // TODO set in config
    },
    mutations: {
        startLoading(state) {
            state.loading = true
        },
        // stopLoading(state) {
        //     state.loading = false
        // },
        setFuneral(state, {funeral}) {
            state.funeral = funeral
            state.loading = false
        },
        login(state, {loggedInAs}) {
            state.isAdmin = true
            state.loggedInAs = loggedInAs
        },
        logout(state) {
            state.isAdmin = false
            state.loggedInAs = null
        },
        setFunerals(state, {funerals}) {
            state.funerals = funerals
            state.loading = false
        },
        removeFuneral(state, {funeralId}) {
            const funerals = state.funerals as Funeral[]
            state.funerals = funerals.filter(f => f.id !== funeralId) as any
            state.loading = false
        },
        addMessage(state, {message}) {
            const funeral: any = state?.funeral
            funeral.messages?.push(message)
        },
    },
    actions: {
        clearFuneral(context) {
            context.commit('setFuneral', {funeral: null})
        },

        async loadFuneral(context, {funeralId}) {
            context.commit('startLoading')

            const funeralDoc = await firebase.firestore()
                .collection('clients').doc('eraly')
                .collection('funerals').doc(funeralId).get()

            const funeralData = funeralDoc.data()

            if(funeralData !== undefined) {

                const messagesDocs = await firebase.firestore()
                    .collection('clients').doc('eraly')
                    .collection('funerals').doc(funeralId)
                    .collection('messages').get()

                const messages: Array<Message> = []

                messagesDocs.forEach((messageDoc) => {

                    const messageData = messageDoc.data()

                    const message: Message = {
                        name: messageData.name,
                        message: messageData.message,
                        timestamp: messageData.timestamp.toDate()
                    }

                    messages.push(message)
                })

                const SITE = 'eraly' // TODO hardcoded site

                const letterReference = funeralData.letterReference ?
                    await firebase.storage().ref().child(`${SITE}/${funeralData.letterReference}`).getDownloadURL()
                    : null

                const pictureReference = funeralData.pictureReference ?
                    await firebase.storage().ref().child(`${SITE}/${funeralData.pictureReference}`).getDownloadURL()
                    : null

                const funeral: Funeral = {
                    id: funeralDoc.id,
                    timestamp: funeralData?.timestamp?.toDate(),

                    name: funeralData.name,
                    gender: funeralData.gender,
                    pictureReference: pictureReference,

                    text: funeralData.text,
                    letterReference: letterReference,

                    spouse: funeralData.spouse || null,
                    spouseDiedAt: funeralData.spouseDiedAt || null,

                    familyEmail: funeralData.familyEmail || null,
                    familyNumber: funeralData.familyNumber || null,

                    messages: messages // TODO sort on date
                }

                context.commit('setFuneral', {funeral})
            }
        },

        async loadFunerals(context) {
            context.commit('startLoading')

            const funeralDocs = await firebase.firestore()
                .collection('clients').doc('eraly')
                .collection('funerals').get()

            const funerals: Array<Funeral> = []

            funeralDocs.forEach((funeralDoc) => {

                const funeralData = funeralDoc.data()

                  const funeral: Funeral = {
                    id: funeralDoc.id,
                    timestamp: funeralData?.timestamp?.toDate(),

                    name: funeralData.name,
                    gender: funeralData.gender,
                    pictureReference: null,

                    text: funeralData.text,
                    letterReference: null,

                    spouse: funeralData.spouse || null,
                    spouseDiedAt: funeralData.spouseDiedAt || null,

                    familyEmail: funeralData.familyEmail || null,
                    familyNumber: funeralData.familyNumber || null,

                    messages: []
                }

                funerals.push(funeral) // TODO sort on date
            })

            context.commit('setFunerals', {funerals})
        },

        async deleteFuneral(context, {funeralId}) {

            context.commit('startLoading')

            console.log('deleting ' + funeralId)

            // TODO does NOT remove images or messages
            await firebase.firestore()
              .collection('clients').doc('eraly')
              .collection('funerals').doc(funeralId)
              .delete()

            context.commit('removeFuneral', {funeralId})
        },

        logout(context) {
            context.commit('logout')
        },

        login(context, {loggedInAs}) {
            context.commit('login', {loggedInAs})
        },

        addMessage(context, {message}) {
            context.commit('addMessage', {message})
        }
    },
    getters: {
        loading: (state): boolean => {
            return state.loading
        },
        funeral: (state): null | Funeral => {
            return state.funeral
        },
        funerals: (state): Array<Funeral> => {
            return state.funerals
        },

        isAdmin: (state): boolean => {
            return state.isAdmin
        },
        loggedInAs: (state): null | string => {
            return state.loggedInAs
        }
    }
})

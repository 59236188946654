








































































































































































































// @ is an alias to /src
import Logout from '@/components/Logout.vue'

import fb from 'firebase/app'
import 'firebase/firestore'

import { firebase } from '@/firebase'
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import dayjs from 'dayjs'
import slugify from 'slugify'


// timestamp: funeralData.timestamp,
//
// name: funeralData.name,
// gender: funeralData.gender,
// pictureReference: pictureReference,
//
// text: funeralData.text,
// letterReference: letterReference,
//
// spouse: funeralData.spouse || null,
// spouseDiedAt: funeralData.spouseDiedAt || null,
//
// familyNumber: funeralData.familyNumber || null,


export default Vue.extend({
    name: 'Admin',
    data: () => ({
        id: '',
        loading: true,

        saving: false,

        valid: true,
        name: '',
        text: '',
        spouse: '',
        spouseDiedAt: '',
        familyEmail: '',
        emailRules: [
            (v: any) => !v || /.+@.+\..+/.test(v) || 'E-mail is niet correct',
        ],
        gender: null,
        genders: [
            {text: 'Man', value: 'male'},
            {text: 'Vrouw', value: 'female'},
        ],
        date: '',
        dateMenu: false,
        time: '',
        timeMenu: false,

        pictureFile: null,
        pictureFileOriginal: '',
        pictureLoading: false,
        letterFile: null,
        letterFileOriginal: '',
        letterLoading: false,


        fileRules: [
            (value: any) => {
                if(value && value.size > 2000000) {
                    return 'Bestand mag maximaal 2 MB zijn!'
                }

                return true
            }
        ]
    }),
    computed: {
        computedDateFormatted () {
            const date = dayjs(this.date as any, 'YYY-MM-DD')

            return date.isValid() ? date.format('DD/MM/YYYY') : null
        },
        ...mapGetters([
            'isAdmin',
            'funeral',
        ])
    },
    components: {
        Logout
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if(isValid) {
                this.saving = true

                console.log('Data is valid, submitting')

                const SITE = 'eraly' // TODO hardcoded

                const timestampString = `${this.date} ${this.time}`
                const timestamp = dayjs(timestampString, 'YYYY-MM-DD HH:mm')

                const fileDate = timestamp.format('DD-MM-YYYY')
                const slug = slugify(this.name)

                const filePrefix = `${fileDate}-${slug}`

                let letterFileName = this.letterFileOriginal ? this.letterFileOriginal : null
                let pictureFileName = this.pictureFileOriginal ? this.pictureFileOriginal : null

                if(this.letterFile) {

                    if(this.letterFileOriginal) {
                        console.log(`Removing original letter file - ${this.letterFileOriginal}`)
                        await firebase.storage().ref().child(`${SITE}/${this.letterFileOriginal}`).delete()
                        console.log(`Removing original letter file - ${this.letterFileOriginal} - done`)
                    }

                    const file = this.letterFile
                    const extension = (file as any).name.split('.').pop();

                    const filename = `${filePrefix}-brief.${extension}`

                    console.log(`Uploading letter file - ${filename}`)
                    await firebase.storage().ref().child(`${SITE}/${filename}`).put(file as any)
                    console.log(`Uploading letter file - ${filename} - done`)

                    letterFileName = filename
                }

                if(this.pictureFile) {

                    if(this.pictureFileOriginal) {
                        console.log(`Removing original picture file - ${this.pictureFileOriginal}`)
                        await firebase.storage().ref().child(`${SITE}/${this.pictureFileOriginal}`).delete()
                        console.log(`Removing original picture file - ${this.pictureFileOriginal} - done`)
                    }

                    const file = this.pictureFile
                    const extension = (file as any).name.split('.').pop();

                    const filename = `${filePrefix}-foto.${extension}`

                    console.log(`Uploading picture file - ${filename}`)
                    await firebase.storage().ref().child(`${SITE}/${filename}`).put(file as any)
                    console.log(`Uploading picture file - ${filename} - done`)

                    pictureFileName = filename
                }

                // TODO hardcoded

                console.log(`Saving data - ${filePrefix}`)
                await firebase.firestore()
                    .collection('clients').doc('eraly')
                    .collection('funerals').doc(filePrefix).set({

                        timestamp: fb.firestore.Timestamp.fromDate(timestamp.toDate()),

                        name: this.name,
                        gender: this.gender,
                        pictureReference: pictureFileName,

                        text: this.text,
                        letterReference: letterFileName,

                        spouse: this.spouse || null,
                        spouseDiedAt: this.spouseDiedAt || null,

                        familyEmail: this.familyEmail || null,
                        familyNumber: null, // TODO implement this
                    })
                console.log(`Saving data - ${filePrefix} - done`)


                if(filePrefix !== this.id) {
                    console.log(`Removing original - ${this.id}`)
                    await firebase.firestore()
                        .collection('clients').doc('eraly')
                        .collection('funerals').doc(this.id).delete()
                    console.log(`Removing original - ${this.id} - done`)
                }

                this.$router.push({name: 'admin'})

                this.saving = false
            }

        },
        async loadFuneral(funeralId: string) {
            this.loading = true

            const funeralDoc = await firebase.firestore()
                .collection('clients').doc('eraly')
                .collection('funerals').doc(funeralId).get()

            const funeralData: any = funeralDoc.data()

            this.id = funeralId

            this.name = funeralData.name
            this.text = funeralData.text
            this.spouse = funeralData.spouse
            this.spouseDiedAt = funeralData.spouseDiedAt
            this.familyEmail = funeralData.familyEmail
            this.gender = funeralData.gender
            this.date = dayjs(funeralData.timestamp.toDate()).format('YYYY-MM-DD')
            this.time = dayjs(funeralData.timestamp.toDate()).format('HH:mm')
            this.pictureFileOriginal = funeralData.pictureReference
            this.letterFileOriginal = funeralData.letterReference

            this.loading = false
        }
    },
    async created() {
        // setup data fields

        // TODO check user

        const getCurrentUser = () => {
            return new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                }, reject);
            })
        };

        const user: any = await getCurrentUser()
        const email = user?.email

        // check token
        const userToken = await user.getIdTokenResult()
        const claims = userToken['claims']

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const item: any = this

        if (claims?.privileges?.admin &&
            (claims?.site === 'eraly' || claims?.site === '*')) { // TODO remove hardcoded site
            console.log('logged in')
            item.login({loggedInAs: email})

            // load funeral if is admin
            const funeralId: any = this.$route.params.id


            await item.loadFuneral(funeralId)
        }
    },
})













































































































































































































// @ is an alias to /src
import Logout from '@/components/Logout.vue'

import { firebase } from '@/firebase'
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import dayjs from 'dayjs'
import slugify from 'slugify'


// timestamp: funeralData.timestamp,
//
// name: funeralData.name,
// gender: funeralData.gender,
// pictureReference: pictureReference,
//
// text: funeralData.text,
// letterReference: letterReference,
//
// spouse: funeralData.spouse || null,
// spouseDiedAt: funeralData.spouseDiedAt || null,
//
// familyNumber: funeralData.familyNumber || null,


export default Vue.extend({
    name: 'Admin',
    data: () => ({
        saving: false,

        valid: true,
        name: '',
        text: '',
        spouse: '',
        spouseDiedAt: '',
        familyEmail: '',
        emailRules: [
            (v: any) => !v || /.+@.+\..+/.test(v) || 'E-mail is niet correct',
        ],
        gender: null,
        genders: [
            {text: 'Man', value: 'male'},
            {text: 'Vrouw', value: 'female'},
        ],
        date: null,
        dateMenu: false,
        time: null,
        timeMenu: false,

        pictureFile: null,
        pictureLoading: false,
        letterFile: null,
        letterLoading: false,

        fileRules: [
            (value: any) => {
                if(value && value.size > 2000000) {
                    return 'Bestand mag maximaal 2 MB zijn!'
                }

                return true
            }
        ]
    }),
    computed: {
        computedDateFormatted () {
            const date = dayjs(this.date as any, 'YYY-MM-DD')

            return date.isValid() ? date.format('DD/MM/YYYY') : null
        },
        ...mapGetters([
            'isAdmin',
            'loading',
            'funeral',
        ])
    },
    components: {
        Logout,
    },
    methods: {
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if(isValid) {
                this.saving = true

                console.log('submit data')

                const timestampString = `${this.date} ${this.time}`
                const timestamp = dayjs(timestampString, 'YYYY-MM-DD HH:mm')

                const fileDate = timestamp.format('DD-MM-YYYY')
                const slug = slugify(this.name)

                const filePrefix = `${fileDate}-${slug}`

                let letterFileName = null
                let pictureFileName = null

                if(this.letterFile) {

                    const file = this.letterFile
                    const extension = (file as any).name.split('.').pop();

                    const filename = `${filePrefix}-brief.${extension}`

                    const SITE = 'eraly' // TODO hardcoded

                    await firebase.storage().ref().child(`${SITE}/${filename}`).put(file as any)
                    console.log('Uploading done')

                    letterFileName = filename
                }

                if(this.pictureFile) {

                    const file = this.pictureFile
                    const extension = (file as any).name.split('.').pop();

                    const filename = `${filePrefix}-foto.${extension}`

                    const SITE = 'eraly' // TODO hardcoded

                    await firebase.storage().ref().child(`${SITE}/${filename}`).put(file as any)
                    console.log('Uploading done')

                    pictureFileName = filename
                }

                // TODO hardcoded
                await firebase.firestore()
                    .collection('clients').doc('eraly')
                    .collection('funerals').doc(filePrefix).set({

                        timestamp: firebase.firestore.Timestamp.fromDate(timestamp.toDate()),

                        name: this.name,
                        gender: this.gender,
                        pictureReference: pictureFileName,

                        text: this.text,
                        letterReference: letterFileName,

                        spouse: this.spouse || null,
                        spouseDiedAt: this.spouseDiedAt || null,

                        familyEmail: this.familyEmail || null,
                        familyNumber: null, // TODO implement this
                })

                this.$router.push({name: 'admin'})

                this.saving = false
            }

        },
        ...mapActions([
            'clearFuneral',
            'login'
        ]),
    },
    async created() {

        // TODO check user

        const getCurrentUser = () => {
            return new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                }, reject);
            })
        };

        const user: any = await getCurrentUser()
        const email = user?.email

        // check token
        const userToken = await user.getIdTokenResult()
        const claims = userToken['claims']

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const item: any = this

        if (claims?.privileges?.admin &&
            (claims?.site === 'eraly' || claims?.site === '*')) { // TODO remove hardcoded site
            console.log('logged in')
            item.login({loggedInAs: email})


            // this.clearFuneral()
        }
    },
})


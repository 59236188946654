import { Api } from "./client";

const TOKEN = process.env.VUE_APP_WOWZA_API_PERSONAL_TOKEN;

const WowzaAPI = new Api({
  baseApiParams: {
    headers: {
      Authorization: `Bearer ${TOKEN}`
    }
  }
});

export { WowzaAPI };













































































































































































declare const PDFDocument: any
declare const blobStream: any

import Vue from 'vue'
import dayjs from 'dayjs'
import {mapGetters, mapActions} from 'vuex'

import { WOWZA_STREAM_ID, LIVE_VIDEO_URL } from '@/config'

import { firebase, getFuneralMessages } from '@/firebase'
import { Funeral, Message } from '@/global'
import Logout from '@/components/Logout.vue'
import StreamControlsComponent from '@/components/StreamControlsComponent.vue'
import LiveVideoPlayerVideoJS from '@/components/LiveVideoPlayerVideoJS.vue'

export default Vue.extend({
    name: 'Admin',
    data: () => ({
      WOWZA_STREAM_ID,
      LIVE_VIDEO_URL,
      openVideo: false
    }),
    computed: {
        sortedFunerals(): {funeralsInPast: Array<Funeral>; funeralsInFuture: Array<Funeral>} {

            const funeralsInPast: Array<Funeral> = []
            const funeralsInFuture: Array<Funeral> = []

            if(this.funerals) {
                this.funerals.forEach((funeral: Funeral) => {

                    if(dayjs(funeral.timestamp).isBefore(dayjs(), 'day')) {
                        funeralsInPast.push(funeral)
                    } else {
                        funeralsInFuture.push(funeral)
                    }
                })
            }

            funeralsInPast.sort((a, b) => {
                return dayjs(a.timestamp).isAfter(dayjs(b.timestamp)) ? -1 : 1
            })
            funeralsInFuture.sort((a, b) => {
                return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1
            })

            return {
                funeralsInPast,
                funeralsInFuture
            }
        },
        ...mapGetters([
            'isAdmin',
            'loggedInAs',
            'loading',
            'funerals',
        ])
    },
    components: {
        Logout,
        StreamControlsComponent,
        LiveVideoPlayerVideoJS
    },
    methods: {
        ...mapActions([
            'login',
            'loadFunerals',
            'deleteFuneral'
        ]),
      async deleteFuneralConfirm(funeral: Funeral) {

          if(await this.$confirm(
              `Bent u zeker dat u de plechtigheid wilt verwijderen?<br/>
                <b>${funeral.name} (${dayjs(funeral.timestamp).format('DD/MM/YYYY')})</b>`,
              {
                title: 'Verwijderen',
                color: 'error',
                buttonTrueText: 'Verwijderen',
                buttonFalseText: 'Annuleren',
                buttonTrueColor: 'error'
              })) {
            this.deleteFuneral({funeralId: funeral.id})
          }
      },
        async downloadFuneralMessages(funeral: Funeral) {

            console.log('downloadFuneralMessages')
            console.log(funeral)

            // eslint-disable-next-line no-undef
            const doc: any = new PDFDocument()

            // eslint-disable-next-line no-undef
            const stream: any = doc.pipe(blobStream())

            const messages: Message[] = await getFuneralMessages(funeral)

            if(messages.length === 0) {

              await this.$confirm(
                  `Nog geen steunbetuigingen beschikbaar.`,
                  {
                    title: ' ',
                    color: 'info',
                    buttonTrueText: 'OK',
                    buttonFalseText: '',
                    buttonTrueColor: 'info'
                  })

              return
            }

            doc.font('Times-Bold').fontSize(30).text('Steunbetuigingen')
            doc.font('Times-Roman').fontSize(22).text(funeral.name)

            doc.font('Times-Roman').fontSize(35).text(' ')

            messages.forEach(m => {
              doc.font('Times-Bold').fontSize(16).text(m.name)
              doc.font('Times-Roman').fontSize(16).text(m.message)
              doc.font('Times-Roman').fontSize(25).text(' ')
            })

            doc.end()

            stream.on('finish', function () {

              // get a blob you can do whatever you like with
              const blob = stream.toBlob('application/pdf')

              // or get a blob URL for display in the browser
              // const url = stream.toBlobURL("application/pdf");
              // const iframe = document.querySelector("iframe");
              // iframe.src = url;

              const a = document.createElement('a')
              document.body.appendChild(a)
              a.style.display = 'none'

              let url = window.URL.createObjectURL(blob)
              console.log('url')
              console.log(url)

              a.href = url
              a.download = `Steunbetuigingen - ${funeral.name}.pdf`
              a.click()
              window.URL.revokeObjectURL(url)

            })

      },
    },
    async created() {

        // TODO check user

        const getCurrentUser = () => {
            return new Promise((resolve, reject) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                    unsubscribe();
                    resolve(user);
                }, reject);
            })
        };

        const user: any = await getCurrentUser()
        const email = user?.email

        // check token
        const userToken = await user.getIdTokenResult()
        const claims = userToken['claims']

        if (claims?.privileges?.admin &&
            (claims?.site === 'eraly' || claims?.site === '*')) { // TODO remove hardcoded site
            console.log('logged in')
            this.login({loggedInAs: email})

            this.loadFunerals()
        }
    },
})


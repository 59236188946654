



































import Vue from 'vue'
import LiveVideoPlayerVideoJS from "@/components/LiveVideoPlayerVideoJS.vue";

import { LIVE_VIDEO_URL } from '@/targets/eraly/eraly'

export default Vue.extend({
    name: 'Admin',
    data: () => ({
        live_url: LIVE_VIDEO_URL,
    }),
    components: {
        'LiveVideoPlayerVideoJS': LiveVideoPlayerVideoJS
    },
})


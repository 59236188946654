// Streams
// TODO make new getStreams, and rename this one to getStreamSummary
import { WowzaAPI } from "@/wowza/api/api";
import { Stream, StreamDetail, Transcoder, WowzaStream, WowzaTranscoders } from '@/global'


export async function getStreams() {
  try {
    const result = await WowzaAPI.liveStreams.listLiveStreams();

    const promises = result.data.live_streams.map(s => {
      return getStreamDetail(s.id ?? "");
    });

    const results = await Promise.all(promises);

    return results.map(
        (ws): Stream => {
          return {
            id: ws.id ?? "",
            name: ws.name ?? "",
            resolution: `${ws.aspect_ratio_height}p`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rtmp_link: `${ws.source_connection_information?.primary_server}/${ws.source_connection_information?.stream_name}`,
            target_link: `${ws.player?.hls_playback_url}`,
            is_authenticated: !(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ws.source_connection_information?.disable_authentication
            ),
            is_passthrough: ws.transcoder_type === "passthrough",
            is_recording: !!ws.recording,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targets: ws.stream_targets?.map(t => t.id)
          };
        }
    );

    // return result.data.live_streams;
  } catch (e) {
    console.log(e);
  }

  return [];
}

export async function getStreamDetail(stream_id: string) {
  const result = await WowzaAPI.liveStreams.showLiveStream(stream_id);
  return result.data.live_stream;
}

export async function getStreamState(stream_id: string) {
  const result = await WowzaAPI.liveStreams.showLiveStreamState(stream_id);
  return result.data.live_stream;
}

export async function getStreamStats(stream_id: string) {
  const result = await WowzaAPI.liveStreams.showLiveStreamStats(stream_id);
  return result.data.live_stream;
}

// Transcoders
export async function getTranscoders() {
  const result = await WowzaAPI.transcoders.listTranscoders();
  return result.data.transcoders;
}

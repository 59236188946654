import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { firebase } from '@/firebase'

import HomePage from '../views/HomePage.vue' // TODO make a proper homepage
import FuneralPage from '../views/FuneralPage.vue'

import AdminPage from '../views/AdminPage.vue'
import AdminEditFuneralPage from '../views/AdminEditFuneralPage.vue'
import LoginPage from '../views/LoginPage.vue'
import AdminCreateFuneralPage from "@/views/AdminCreateFuneralPage.vue";
import AdminTestPage from "@/views/AdminTestPage.vue";




const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  })
};


Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/c/:id',
    name: 'funeral',
    component: FuneralPage
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/admindashboard',
    name: 'admin',
    component: AdminPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admindashboard/edit/:id',
    name: 'edit',
    component: AdminEditFuneralPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admindashboard/create',
    name: 'create',
    component: AdminCreateFuneralPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admindashboard/test',
    name: 'test',
    component: AdminTestPage,
    meta: {
      requiresAuth: true
    }
  },
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  { path: "*", component: HomePage }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !await getCurrentUser()){
    next({ name: 'login' });
  }else{
    next();
  }
});

export default router









import Vue from 'vue'
import { firebase } from '@/firebase'
import {mapGetters, mapActions} from 'vuex';

export default Vue.extend({
    name: 'Logout',
    computed: {
        ...mapGetters([
            'loggedInAs',
        ])
    },
    methods: {
        ...mapActions([
            'logout',
        ]),
        async _logout() {
            console.log('logout here')
            this.logout()
            console.log('logout here and here')
            await firebase.auth().signOut()

            this.$router.push({name: 'login'})
        },
    },
})


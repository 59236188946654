



















































































































































































import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import dayjs from 'dayjs'

import LiveVideoPlayerVideoJS from "@/components/LiveVideoPlayerVideoJS.vue";

import { Message } from '@/global'

// TODO fix cluncky fb imports
import { firebase } from "@/firebase";

import fb from 'firebase/app'
import 'firebase/firestore'

import { LIVE_VIDEO_URL } from '@/targets/eraly/eraly'

export default Vue.extend({
    name: 'FuneralPage',
    components: {
      LiveVideoPlayerVideoJS
    },
    data: () => ({
        id: '',

        live_url: LIVE_VIDEO_URL,

        saving: false,
        saved: false,
        valid: true,

        name: '',
        message: '',
    }),
    computed: {
        hoursToLive() {

            if(this.funeral) {
                return dayjs(this.funeral.timestamp).diff(dayjs(), 'hour', true)
            }

            return null
        },
        ...mapGetters([
            'loading',
            'funeral'
        ])
    },
    methods: {
        ...mapActions([
            'loadFuneral',
            'addMessage',
        ]),
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if(isValid) {
                this.saving = true

                console.log('submit message')

                // TODO hardcoded
                await firebase.firestore()
                    .collection('clients').doc('eraly')
                    .collection('funerals').doc(this.id)
                    .collection('messages').add({

                        timestamp: fb.firestore.Timestamp.fromDate(dayjs().toDate()),

                        name: this.name,
                        message: this.message,
                    })

                const message: Message = {
                    name: this.name,
                    message: this.message,
                    timestamp: dayjs().toDate()
                }

                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const item: any = this
                item.addMessage({message})

                this.saving = false
                this.saved = true
            }

        }
    },
    async created() {

        this.id = this.$route.params.id

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const item: any = this
        item.loadFuneral({funeralId: this.id})

    },
    watch: {
        $route(to, from) {
            console.log('watched route')
            console.log(to.param.id)
            // TODO reload state -- react to route changes...
        }
    }
})


import { WowzaAPI } from "@/wowza/api/api";

export async function startStream(stream_id: string) {
  const result = await WowzaAPI.liveStreams.startLiveStream(stream_id);
  return result.data.live_stream;
}

export async function stopStream(stream_id: string) {
  const result = await WowzaAPI.liveStreams.stopLiveStream(stream_id);
  return result.data.live_stream;
}












import { firebase } from '@/firebase'
import * as firebaseui from 'firebaseui'

import Vue from 'vue'

import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'Login',
  computed: {
  },
  data () {
    return {
      ui: null
    }
  }
  ,
  components: {

  },
  methods: {
    ...mapActions([
      'login',
    ]),

    startLogin() {
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: (currentUser: any, credential: any, redirectUrl: string) => {
            console.log('signInSuccessWithAuthResult ')

            return false; // no redirect
          },
          uiShown: () => {
            console.log('uiShown')
            // document.getElementById('loader').style.display = 'none';
          }
        },
        // signInSuccessUrl: '/',
        signInFlow: 'popup',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // firebaseui.auth.FacebookAuthProvider.PROVIDER_ID,
          // firebaseui.auth.TwitterAuthProvider.PROVIDER_ID,
          // firebaseui.auth.GithubAuthProvider.PROVIDER_ID,
          (firebase.auth as any).EmailAuthProvider.PROVIDER_ID,
          // firebaseui.auth.PhoneAuthProvider.PROVIDER_ID,
          // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
        ],
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('');
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      if(!this.ui) {
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth()) as any;
      }

      // The start method will wait until the DOM is loaded.
      // if (ui.isPendingRedirect()) {
      (this.ui as any).start('#firebaseui-auth-container', uiConfig);
      // }
    },

    checkUser() {

      console.log('check user')

      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          // User is signed in.
          // const displayName = user.email;
          // var email = user.email;
          // var emailVerified = user.emailVerified;
          // var photoURL = user.photoURL;
          // var uid = user.uid;
          // var phoneNumber = user.phoneNumber;
          // var providerData = user.providerData;

          // check token
          const userToken = await user.getIdTokenResult()
          const claims = userToken['claims']

          if(claims?.privileges?.admin &&
                  (claims?.site === 'eraly' || claims?.site === '*' )) { // TODO remove hardcoded site
            console.log('logged in')
            this.login({loggedInAs: user.email})

            this.$router.push({name: 'admin'})
          }

        } else {
          // User is signed out.
          console.log('Signed out');
          this.startLogin()
        }

      }, (error) => {
        console.log(error);
      });
    }
  },

  mounted() {
    this.checkUser()
  }
})

